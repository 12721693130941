
import React, { useRef, useEffect, useState } from 'react';
import MabSlideshow from './MabSlideshow';
import './mabSlideshow.scss';


export default function ViewMabSlideshow ( { images } ) {
    
    const [isActive, setIsActive] = useState(false);

    const slideshowRef = useRef(null);
    useEffect(() => {
        if (!isActive) {
            setIsActive(true);
            new MabSlideshow( {
                container: slideshowRef.current,
                handlesResize: false
            } );
        }
    }, [isActive] );

    const getSlides = () => {
        const slides = images.map( (item, index) => 
            <div className='mab-slideshow--slide js-slide' key={ index }>
                { item }
            </div>
        );
        return slides;
    }

    return (
        <div className='mab-slideshow--container js-slideshow' ref={ slideshowRef } data-is-full-screen='false' data-height-ratio='0.5625'>
            <div className='js-slidesContainer'>
                { getSlides() }
            </div>
        </div>
    );

}