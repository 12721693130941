import React, { useRef, useEffect } from 'react';
import Img from 'gatsby-image';
import { utilAnimate } from '../../utils/utilAnimate';
import styles from './stylesMab.module.scss';


export default function ViewMabOverlay ( { item, currItem } ) {

  	const containerRef = useRef(null);
	useEffect(() => {
		item.title === currItem ? utilAnimate.fadeIn(containerRef.current) : utilAnimate.fadeOut(containerRef.current);
	});

  	return (
    	<div ref={ containerRef } className={ styles.overlay }>
			{ item.isSlideshow
			? <item.Slideshow />
			: <Img fixed={ item.overlayNode.childImageSharp.fixed } key={ item.overlayNode.id } alt={ item.title } />
			}
			<div className={ styles.imgTitle }>
				<Img fixed={ item.titleNode.childImageSharp.fixed } key={ item.titleNode.id } alt={ item.title } />
			</div>
    	</div>
  	);

}
