import React from 'react';
import ViewMabSlideshow from './ViewMabSlideshow';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


export default function ViewMabSlideshowTrusted () { 

	const data = useStaticQuery(graphql`
	{
		allFile(filter: {relativeDirectory: {eq: "mab"}, base: {regex: "/bkg--trusted/"}}, sort: { fields: [base] }) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 1920, height: 1080, quality: 80) {
							...GatsbyImageSharpFixed_noBase64
						}
					}
				}
			}
		}
	}
	`)

	const images = data.allFile.edges.map( ( { node } ) => 
		<Img fixed={ node.childImageSharp.fixed } key={ node.id } alt='trusted image' />
	);
	  
	return (
		<>
			<ViewMabSlideshow images={ images } />
		</>
	);

}
