
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { data } from './dataMab';
import ViewMabOverlay from './ViewMabOverlay';
import ButtonMabItem from './ButtonMabItem';
import styles from './stylesMab.module.scss';


export default function ViewMab () {


    const [ currItem, setCurrItem ] = useState(data[0].title);
    const onItemSected = (item) => {
        const newItem = item;
        setCurrItem(newItem);
    }

    const overlaysQuery = useStaticQuery(graphql`
	{
		bkgs: allFile(filter: {relativeDirectory: {eq: "mab"}, base: {regex: "/bkg/"}}) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 1920, height: 1080, quality: 80) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
					}
				}
			}
        }
        titles: allFile(filter: {relativeDirectory: {eq: "mab"}, base: {regex: "/frg/"}}) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 978, height: 138, quality: 80) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
					}
				}
			}
		}
	}
    `);
    
    const getImgNodes = () => { //use the retrieved file names to match the data up and assign the nodes for Gatsby IMG
        const bkgList = overlaysQuery.bkgs.edges;
        data.forEach( item => {
            const matchedImgNode = bkgList.find( img => img.node.base === item.overlaySrc ); 
            item.overlayNode = matchedImgNode.node;
        });
        const titleList = overlaysQuery.titles.edges;
        data.forEach( item => {
            const matchedImgNode = titleList.find( img => img.node.base === item.titleSrc ); 
            item.titleNode = matchedImgNode.node;
        });
    }
    getImgNodes();

    const getOverlays = () => {
        const overlays = data.map( item => 
            <ViewMabOverlay key={ item.title } item={ item } currItem={ currItem } />
        );
        return overlays;
    }

    const getButtons = () => {
        const buttons = data.map( item => 
            <li key={ item.title }>
                <ButtonMabItem item={ item } currItem={ currItem } selectItem={ onItemSected } />
            </li>
        );
        return buttons;
    }

    return (
        <>
           <div className={ styles.frg }>
             { getOverlays() }
           </div>
           <nav className={ styles.menu }>
                <h2 className={ styles.title }>Why MAB?</h2>
                <ul>
                    { getButtons() }
                </ul>
            </nav>
        </>
    );

}