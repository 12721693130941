
import { gsap } from 'gsap/all';

export const utilAnimate = {
    fadeIn: (element, speed=0.3) => {
        element.style.display = 'block';
        gsap.to(element, speed, {
            opacity: 1
        });
    },
    fadeOut: (element, speed=0.3) => {
        gsap.to(element, speed, {
            opacity: 0,
            onComplete: () => {
                element.style.display = 'none';
            }
        });
    }
}
