
import CapableInactive from '../../images/mab/icon--capable-blue.png';
import CapableActive from '../../images/mab/icon--capable-white.png';
import TrustedInactive from '../../images/mab/icon--trusted-blue.png';
import TrustedActive from '../../images/mab/icon--trusted-white.png';
import CapacityInactive from '../../images/mab/icon--capacity-blue.png';
import CapacityActive from '../../images/mab/icon--capacity-white.png';
import ProvenInactive from '../../images/mab/icon--proven-blue.png';
import ProvenActive from '../../images/mab/icon--proven-white.png';
import PassionateInactive from '../../images/mab/icon--passionate-blue.png';
import PassionateActive from '../../images/mab/icon--passionate-white.png';
import CollaborativeInactive from '../../images/mab/icon--collaborative-blue.png';
import CollaborativeActive from '../../images/mab/icon--collaborative-white.png';
import ReadyInactive from '../../images/mab/icon--ready-blue.png';
import ReadyActive from '../../images/mab/icon--ready-white.png';
import ViewMabSlideshowCapable from '../mabSlideshow/ViewMabSlideshowCapable';
import ViewMabSlideshowTrusted from '../mabSlideshow/ViewMabSlideshowTrusted';
import ViewMabSlideshowProven from '../mabSlideshow/ViewMabSlideshowProven';
import ViewMabSlideshowPassionate from '../mabSlideshow/ViewMabSlideshowPassionate';


export const data = [
    { 
        title: 'Capable', 
        overlaySrc: 'bkg--capable-1.jpg',
        overlayNode: null, 
        titleSrc: 'frg--capable-text.png',
        titleNode: null, 
        iconInactive: CapableInactive,
        iconActive: CapableActive,
        isSlideshow: true,
        Slideshow: ViewMabSlideshowCapable
    },
    { 
        title: 'Trusted',
        overlaySrc: 'bkg--trusted-1.jpg',
        overlayNode: null, 
        titleSrc: 'frg--trusted-text.png',
        titleNode: null, 
        iconInactive: TrustedInactive,
        iconActive: TrustedActive,
        isSlideshow: true,
        Slideshow: ViewMabSlideshowTrusted
    },
    { 
        title: 'Capacity', 
        overlaySrc: 'bkg--capacity.jpg',
        overlayNode: null, 
        titleSrc: 'frg--capacity-text.png',
        titleNode: null, 
        iconInactive: CapacityInactive,
        iconActive: CapacityActive,
        isSlideshow: false 
    },
    { 
        title: 'Proven',
        overlaySrc: 'bkg--proven-1.jpg',
        overlayNode: null, 
        titleSrc: 'frg--proven-text.png',
        titleNode: null, 
        iconInactive: ProvenInactive,
        iconActive: ProvenActive,
        isSlideshow: true,
        Slideshow: ViewMabSlideshowProven 
    },
    { 
        title: 'Passionate', 
        overlaySrc: 'bkg--passionate-1.jpg',
        overlayNode: null, 
        titleSrc: 'frg--passionate-text.png',
        titleNode: null, 
        iconInactive: PassionateInactive,
        iconActive: PassionateActive,
        isSlideshow: true,
        Slideshow: ViewMabSlideshowPassionate 
    },
    { 
        title: 'Collaborative',
        overlaySrc: 'bkg--collaborative.jpg',
        overlayNode: null,  
        titleSrc: 'frg--collaborative-text.png',
        titleNode: null, 
        iconInactive: CollaborativeInactive,
        iconActive: CollaborativeActive,
        isSlideshow: false 
    },
    { 
        title: 'Ready', 
        overlaySrc: 'bkg--ready.jpg',
        overlayNode: null, 
        titleSrc: 'frg--ready-text.png',
        titleNode: null, 
        iconInactive: ReadyInactive,
        iconActive: ReadyActive,
        isSlideshow: false 
    }
];