import React from 'react'
import styles from './stylesMab.module.scss';


export default function ButtonMabItem ( { item, currItem, selectItem } ) {
  
  return (
    <button 
      className={ item.title === currItem ? styles.btn__active : styles.btn } 
      onClick={ () => selectItem(item.title) } 
    >
      <img src={ item.iconInactive } className={ styles.inactiveIcon } width='63' height='58' alt={ item.title + ' icon' } />
      <img src={ item.iconActive } className={ styles.activeIcon } width='63' height='58' alt={ item.title + ' icon' } />
      { item.title }
    </button>
  );

}
