import React from 'react';
import SEO from '../components/seo/SEO';
import ViewMab from '../components/mab/ViewMab';


export default function MAB () { 

  return (
    <>
      <SEO title='Why MAB?' />
      <ViewMab />
    </>
  );

}
